@import '../variables';

.paging-container {
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 10px 5px;
  // select {
  .custom-select {
    width: 100px;
    margin: 0 5px;
    &:after {
      top: 13px;
    }
  }
  .button-container {
    display: grid;
    grid-template-columns: repeat(2,2fr) auto repeat(2,2fr);
    height: 40px;
    p {
      border-top: 1px solid @tertiary;
      border-bottom: 1px solid @tertiary;
      padding: 7px;
      text-align:center;
    }
    button {
      font-weight: 700;
      width:100%;
      height: 100%;
      border-radius: 0;
      background-color: transparent;
      border: 1px solid @tertiary;
      font-family: 'Font Awesome 5 Free';
      &[disabled] {
        color: @tertiary;
      }
      &:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right: 0;
      }
      &:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left: 0;
      }
    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .fallback-rules;
}

@supports not (display: grid) {
  .fallback-rules;
}

.fallback-rules {
  .paging-container {
    display: flex;
    div:not(.button-container) {
      flex: 3;
    }
    .button-container {
      flex: 1;
      display: flex;
      button {
        flex: 1;
      }
      p {
        flex: 2;
      }
    }
  }
}

//responsive
@media @ipad-landscape {
}

@media @ipad-portrait {
  .paging-container{
    grid-template-columns:1fr;
    text-align:center;
    grid-gap:10px;
    // .button-container{
    //   button{
    //     width:100%;
    //   }
    // }
  }
}

@media @mobile {

}